import { createContext } from 'react';

const AdminLayoutContext = createContext();
export const AdminLayoutProvider = ({ children }) => {
    const column = 1
    const value = { column }

    return (
        <AdminLayoutContext.Provider value={value}>
            {children}
        </AdminLayoutContext.Provider>
    );
};

export default AdminLayoutContext;