import {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  collection,
  query,
  doc,
  onSnapshot,
  getDoc,
  getDocs,
  addDoc,
  serverTimestamp,
  where,
} from "firebase/firestore";

import { db } from "~/firebase";
import appContext from "~/contexts/AppContext";
import { getDisplayName } from "~/utils/common";
import { datetime_diff } from "~/utils/common";
import { sendNewCommentNotify } from "~/utils/common";

const EachJobPost = () => {
  const currentUserUid = localStorage.getItem("myId");
  const { userData, userAvatar } = useContext(appContext);
  const { id } = useParams();
  // let pid = id.split("=").at(1)
  const pid = useMemo(() => {
    return id.split("=").at(1);
  }, [id]);
  const navigate = useNavigate();
  let clickRef = useRef([]);
  const videoRef = useRef(null);
  const [jobsList, setJobsList] = useState([]);
  const jobsRef = useRef([]);
  const jobListRef = useRef(null);
  const tabsRef = useRef([]);
  const [postUsers, setPostUsers] = useState({});
  const [commentList, setCommentList] = useState([]);
  const inputCommentsRefs = useRef([]);
  const [comment, setComment] = useState("");
  const [minute, setMinute] = useState(0);
  const [rate, setRate] = useState(0);

  const pidRef = useRef(pid);
  // In your render function, display the first `displayedJobs` jobs
  /**
   *  Get posted jobs
   */
  Array.prototype.removeByValue = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  const getJobPosts = (jobId) => {
    const unsubscribe = async () => {
      const docRef = doc(db, "jobpost", pidRef.current);
      try {
        const jobSet = [];
        const newJobs = [...jobsRef.current];
        jobSet.push(newJobs);
        const docSnap = await getDoc(docRef);
        const job = docSnap.data();
        job.jobId = docSnap.id;
        newJobs.push(job);
        setJobsList(newJobs);
        jobListRef.current = newJobs;
        return docSnap;
      } catch (error) {
        console.error("Failed to fetch jobpost data", error);
      }
    };

    return () => {
      unsubscribe();
    };
  };

  const getPostUser = async (id) => {
    if (id == null) {
      return;
    }
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such user document!");
    }
  };

  // Fetch users once on component mount
  const fetchPostUsers = async () => {
    const newPostUsers = {};
    for (const job of jobsList) {
      if (!newPostUsers[job.post_userid]) {
        const user = await getPostUser(job?.post_userid);
        newPostUsers[job.post_userid] = user;
      }
    }
    setPostUsers(newPostUsers);
  };
  /**
   *  Start Getting Comments by Post Id
   */
  const getCommentsByPost = (jobId) => {
    const q = query(
      collection(db, "comments"),
      where("jobid", "==", pidRef.current)
    );
    const unsubscribe = async () => {
      onSnapshot(q, async (querySnapshot) => {
        const commentSet = [];

        await Promise.all(
          querySnapshot.docs.map(async (mdoc) => {
            const comments = mdoc.data();
            const docRef = doc(db, "users", comments.comment_userid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const commentUserInfo = docSnap.data();
              if (commentUserInfo) {
                comments.image = commentUserInfo.image ?? "";
                comments.name = commentUserInfo.username;
              } else {
                comments.image = "";
                comments.name = "";
              }
              commentSet.push(comments);
            }
          })
        );
        commentSet.sort((a, b) => a.timestamp?.seconds - b.timestamp?.seconds);
        setCommentList(commentSet);
      });
    };
    return unsubscribe;
  };

  /**
   *  End Getting Comments by Post Id
   */
  // send quote & comments
  const sendQuote = async (post_id, job_id, uid, color, index) => {
    if (comment == "") return;
    // let uploads = await uploadSelectedFiles()
    const saveData = {
      post_userid: post_id,
      jobid: job_id,
      comment_userid: currentUserUid,
      comment: comment,
      period: minute,
      rate: rate,
      timestamp: serverTimestamp(),
      color: color,
    };
    setComment("");
    // inputCommentsRefs.current[index].value = "";
    const commentData = await writeCommentsToDb(saveData);
    const users = await getCommentsUserListByPost(currentUserUid, post_id);
    await writeCommentUnreadToDb(users, commentData.id);
    sendNewCommentNotify(users, uid, job_id, comment);
  };

  // write comments to DB
  const writeCommentsToDb = async (data) => {
    const debouncedWrite = async () => {
      const newComment = await addDoc(collection(db, "comments"), data);
      return newComment;
    };
    return debouncedWrite();
  };

  const writeCommentUnreadToDb = async (users, commentId) => {
    await users.forEach((user) => {
      addDoc(collection(db, "commentUnread"), {
        userId: user,
        commentId: commentId,
        readCheck: false,
      });
    });
  };

  const getCommentsUserListByPost = async (comment_userid, post_userid) => {
    if (comment_userid == "") {
      return;
    }
    let users = [];
    const q = query(
      collection(db, "comments"),
      where("post_userid", "==", post_userid)
    );

    const docSnap = await getDocs(q);
    users = docSnap.docs.map((docData) => {
      return docData.data().comment_userid;
    });
    if (comment_userid !== post_userid) {
      users.push(post_userid);
    }
    return users
      .filter((value, index, array) => array.indexOf(value) === index)
      .removeByValue(comment_userid);
  };
  // utility function to debounce function calls
  const debounce = (fn, delay) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    fetchPostUsers();
  }, [jobsList]);

  useEffect(() => {
    pidRef.current = pid;
  }, [pid]);

  useEffect(() => {
    pidRef.current = pid;
    // get commnets by posted job
    const unsubscribe2 = getCommentsByPost(pidRef.current);
    // get post jobs
    const unsubscribe1 = getJobPosts(pidRef.current);

    unsubscribe2();
    unsubscribe1();
  }, [pid]);

  // The method to get each uers's profile by user Id
  const viewProfile = (id) => {
    // navigate(`/portfolio/id=${id}`)
    navigate(`/settings/profile-setting`);
  };

  // show comments profile
  const showProfile = (comment_userid) => {
    navigate(`/portfolio/id=${comment_userid}`);
  };

  const hideCommentsByIndex = useCallback((index) => {
    clickRef.current = index;
    // Hide comments for all tabs except the active one
    const currentTab = tabsRef.current[clickRef.current];
    if (currentTab != undefined && currentTab.classList.contains("hidden")) {
      currentTab.classList.remove("hidden");
    } else if (
      currentTab != undefined &&
      !currentTab.classList.contains("hidden")
    ) {
      currentTab.classList.add("hidden");
    }
  });

  return (
    <div className="flex flex-col w-full font-primary max-w-[958px] mx-auto mt-5">
      {/* Job Post window */}
      {jobsList &&
        jobsList.map((job, index) => {
          return (
            <div
              key={job.post_userid}
              className="px-[44px] py-[36px] h-auto bg-[#EFEFEF] rounded-2xl mb-[80px]"
            >
              <div className="flex flex-col">
                <div className="flex flex-row justify-between w-full mb-[20px]">
                  <div
                    className="flex flex-row items-center font-primary gap-2 cursor-pointer"
                    onClick={() => {
                      showProfile(job.post_userid);
                    }}
                  >
                    {Object.keys(postUsers).length &&
                      (postUsers[job.post_userid] &&
                      postUsers[job.post_userid]?.image != null ? (
                        <img
                          src={postUsers[job.post_userid]?.image}
                          className="w-[53px] h-[53px] rounded-full object-cover"
                        />
                      ) : (
                        <>
                          <div
                            className="w-[53px] h-[53px] rounded-full text-center mr-2 flex items-center justify-center"
                            style={{
                              backgroundColor:
                                postUsers[job.post_userid]?.color,
                            }}
                          >
                            <p className="inline-block my-auto font-primary text-white align-middle">
                              {getDisplayName(
                                postUsers[job.post_userid]?.username
                              )}
                            </p>
                          </div>
                        </>
                      ))}
                    <div className="flex flex-col">
                      <span className="text-black font-primary text-[18px] font-bold">
                        <div
                          className="hover:cursor-pointer"
                          onClick={() => {
                            showProfile(job.post_userid);
                          }}
                        >
                          {postUsers[job.post_userid]?.username}{" "}
                        </div>
                      </span>
                      <span className="text-base font-primary text-[#505050">
                        {datetime_diff(
                          job.timestamp?.seconds * 1000,
                          new Date().getTime()
                        )}{" "}
                        ago
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col mb-8">
                  <div className="justify-between">
                    <p className="text-[18px]">{job.description}</p>
                    <div className="flex items-center mb-[5px] gap-3">
                      <p className="text-[15px] font-bold">Needs help with:</p>
                      {job.expertCategories.map((category, idx) => {
                        return (
                          <button
                            key={idx}
                            className="px-[15px] h-[25px] rounded-lg bg-[#232323] text-white text-[15px]"
                          >
                            {category}
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <div className="grid grid-cols-5">
                    <div className="flex flex-row gap-[22.5px]">
                      {job.loomLink && (
                        <iframe
                          title="loom"
                          src={`https://www.loom.com/embed/${
                            job.loomLink.split("/share/")[1]
                          }`}
                          style={{ width: "170px", height: "105px" }}
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                        ></iframe>
                      )}
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      {job.uploadFiles &&
                        job.uploadFiles.map((file, idx) => {
                          return (
                            <img
                              src={file}
                              key={`${job.post_userid}-${index}`}
                              className="w-[169px] h-[105px] object-cover"
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center message mb-[1px] cursor-pointer">
                  <button
                    type="button"
                    className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white rounded-lg"
                  >
                    <svg
                      className="w-8 h-8 text-black"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      {" "}
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                    </svg>
                    <span className="sr-only">Notifications</span>
                  </button>
                  <div
                    className="text-black"
                    onClick={() => hideCommentsByIndex(index)}
                  >
                    Hide Comments...
                  </div>
                </div>
                <div
                  className="flex flex-col"
                  ref={(el) => (tabsRef.current[index] = el)}
                >
                  {commentList &&
                    commentList
                      .filter((value) => value.jobid == job.jobId)
                      .map((data, index) => {
                        return (
                          <div
                            className="flex flex-row items-center gap-2 mb-8"
                            key={`${job.jobId}-${index}`}
                          >
                            <div
                              className="hover:cursor-pointer"
                              key={data.comment_userid}
                              onClick={() => showProfile(data.comment_userid)}
                            >
                              {data.image ? (
                                <img
                                  src={data.image}
                                  className="w-[53px] h-[53px] rounded-full"
                                  alt="profile-icon"
                                />
                              ) : (
                                <div
                                  className="w-[53px] h-[53px] rounded-full text-center mr-2 flex items-center justify-center"
                                  style={{
                                    backgroundColor: data.color
                                      ? data.color
                                      : "#000000",
                                  }}
                                >
                                  <p className="inline-block my-auto text-white align-middle">
                                    {getDisplayName(data.name)}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div
                              className="flex flex-col"
                              key={`${data.comment_userid}-${index}`}
                            >
                              <span
                                className="text-black font-bold text-[18px] hover:cursor-pointer"
                                onClick={() => showProfile(data.comment_userid)}
                              >
                                {data.name}
                              </span>
                              <span className="text-black text-[15px]">
                                {data.comment}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                  <div className="flex flex-row gap-2 mb-1">
                    {userAvatar ? (
                      <img
                        src={userAvatar}
                        className="mr-2 rounded-full w-[53px] h-[53px] object-cover"
                        alt="default avatar"
                      />
                    ) : (
                      <div
                        className="w-[53px] h-[53px] rounded-full flex items-center justify-center text-center mr-2"
                        style={{ backgroundColor: userData?.color }}
                      >
                        <p className="inline-block my-auto text-white align-middle">
                          {getDisplayName(userData?.username)}
                        </p>
                      </div>
                    )}
                    <textarea
                      type="text"
                      ref={(el) => (inputCommentsRefs.current[index] = el)}
                      className="rounded-2xl w-full h-[62.25px] py-[13px] pl-[20.25px]"
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Write a comment..."
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="text-[20px] text-[#363636] font-bold"
                      onClick={() => {
                        sendQuote(
                          job.post_userid,
                          job.jobId,
                          userData.color,
                          index
                        );
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EachJobPost;
