import React from "react";

import { NotFoundIcon } from "../assets/icons";
import { Link } from "react-router-dom";

function Page404() {
  return (
    <div className="flex flex-col items-center space-y-4">
      <NotFoundIcon className="w-full max-w-xs h-auto" aria-hidden="true" />
      <p className="text-gray-700 dark:text-gray-300">
        Page not found. Check the address or{" "}
        <Link to="/" className="text-purple-200">
          go back
        </Link>
        .
      </p>
    </div>
  );
}

export default Page404;
