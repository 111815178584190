import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "~/firebase"; // Assume firebase auth and a function to get user role
import { onAuthStateChanged, signOut } from "firebase/auth";
import { getUserInfoByUid } from "~/utils/common";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize from localStorage if available
    return localStorage.getItem("isLoggedIn") === "true";
  });
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen to auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUser(user);
        // Assuming getUserRole is a function that fetches the role from Firestore or another service
        const userData = await getUserInfoByUid(user.uid);
        setUserRole(userData.role);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userdata", JSON.stringify(userData));
        localStorage.setItem("userRole", userData.role);
        localStorage.setItem("myId", user.uid);
        localStorage.setItem("avatar", userData.avatar || null);
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setUserRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const logout = async (cleanup = () => {}) => {
    try {
      localStorage.clear();
      // Perform setShowMobileMenu, clearConversations functions
      cleanup();
      // Sign out from Firebase and navigate to login page
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        userRole,
        setUserRole,
        loading,
        setLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
