import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { db, auth, functions } from "~/firebase";
import { jobNotification } from "~/const";

export const getDisplayName = (fullName) => {
  if (fullName) {
    const names = fullName.split(" ");
    if (names.length > 1) {
      return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
    } else {
      return `${fullName.charAt(0)}${fullName.charAt(1)}`;
    }
  }
  return "";
};

// Get user Info using userId
export const getUserInfoByUid = async (userId) => {
  const q = query(collection(db, "users"), where("uid", "==", userId));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const userData = querySnapshot.docs[0].data();
    return userData;
  } else {
    console.log(`No userdata for this Id: ${userId} found!`);
  }
};

export const getUserDocRefByUid = async (uid) => {
  const usersCollectionRef = collection(db, "users");
  const q = query(usersCollectionRef, where("uid", "==", uid));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    // Assuming there is only one document with this UID
    return querySnapshot.docs[0].ref;
  } else {
    throw new Error("User Doc Ref not found");
  }
};

export const getAllUsersSkills = async () => {
  let skillSet = {};
  const skillsRef = collection(db, "skills");
  const skillsSnapshot = await getDocs(skillsRef);
  let _skills = skillsSnapshot.docs[0].data().data;

  const profilesRef = collection(db, "profile");
  const profilesSnapshot = await getDocs(profilesRef);
  _skills.forEach((skill) => {
    skillSet[skill] = { count: 0, phoneNumbers: [] };
  });

  for (const profileDoc of profilesSnapshot.docs) {
    const profileData = profileDoc.data();
    const profileSkills = profileData.Skills;
    const profileId = profileData.profileId;

    try {
      const userData = await getUserInfoByUid(profileId);

      if (!userData) {
        console.error(
          "User document does not exist for profile ID:",
          profileId
        );
        continue;
      }
      const phoneNumber = userData?.phone;
      _skills.forEach((skill) => {
        if (profileSkills.includes(skill)) {
          skillSet[skill].count += 1;
          skillSet[skill].phoneNumbers.push(phoneNumber);
        }
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  return skillSet;
};

// SMS notification for new job
export const sendNewJobNotify = async (
  docJobId,
  skillSet,
  expertCategories
) => {
  //   let phones = await getAllUsersPhones();
  let phoneNumbers = [];
  // Get all phone numbers of experts in the selected categories but only if they have the skill, also avoid same phone number
  expertCategories.map((category) => {
    phoneNumbers = phoneNumbers.concat(skillSet[category].phoneNumbers);
  });
  let phones = [...new Set(phoneNumbers)];
  // call Cloud Function to send SMS notification
  const sendJobPostNotification = httpsCallable(
    functions,
    "sendJobPostNotification"
  );
  const message = jobNotification + `${docJobId}`;
  phones.map(async (phone) => {
    await sendJobPostNotification({
      phoneNumber: phone,
      message: message,
      token: auth.currentUser.accessToken,
    })
      .then((result) => {
        console.log("SMS notification sent:", result);
      })
      .catch((error) => {
        console.error("Error sending SMS notification:", error);
      });
    console.log("Sending email notification", message);
  });
};

export const sendNewCommentNotify = async (
  users,
  commentUserName,
  jobId,
  commentTxt
) => {
  // call Cloud Function to send SMS notification
  const sendJobPostNotification = httpsCallable(
    functions,
    "sendJobPostNotification"
  );

  users.map(async (user) => {
    const UserInfo = await getUserInfoByUid(user);
    let subUrl;
    if (UserInfo.role == "expert") {
      subUrl = `/expert/job-post/id=${jobId}`;
    } else {
      subUrl = `/portfolio/id=${user}/order-history`;
    }
    const message =
      `${commentUserName}` +
      " is commented now on expertscreenshare\n" +
      `${commentTxt}\n` +
      `https://expertscreenshare.com${subUrl}`;
    await sendJobPostNotification({
      phoneNumber: UserInfo.phone,
      message: message,
      token: auth.currentUser.accessToken,
    })
      .then((result) => {
        console.log("SMS notification sent:", result);
      })
      .catch((error) => {
        console.error("Error sending SMS notification:", error);
      });
    console.log("Sending email notification", message);
  });
};

export const sendNotificationToSalesRep = async (
  currentUserUid,
  currentUserName
) => {
  let salesPhones = [];
  const collectionRef = collection(db, "users");
  const q = query(collectionRef);
  onSnapshot(q, (querySnapshot) => {
    const promises = querySnapshot.docs.map(async (eachDoc) => {
      const data = eachDoc.data();
      if (data.role === "sales") {
        if (!salesPhones.includes(data.phone)) salesPhones.push(data.phone);
      }
    });
    Promise.all(promises).then(() => {
      const sendNotificationToSalesRep = httpsCallable(
        functions,
        "sendSMSNotification"
      );
      salesPhones.map(async (phone) => {
        await sendNotificationToSalesRep({
          phoneNumber: phone,
          message: `${currentUserName} is calling: https://www.expertscreenshare.com/supportRoom/${currentUserUid}`,
          token: auth.currentUser.accessToken,
        })
          .then((result) => {
            console.log("SMS notification sent:", result);
          })
          .catch((error) => {
            console.error("Error sending SMS notification:", error);
          });
      });
    });
  });
};

export const writeJobPostToDb = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "jobpost"), data);
    return docRef.id;
  } catch (error) {
    console.error("Error adding job post to Firestore: ", error);
  }
};

export const datetime_diff = (date1, date2) => {
  var date1_ms = date1;
  var date2_ms = date2;

  // Calculate the difference in milliseconds
  var difference_ms = Math.abs(date1_ms - date2_ms);

  // Convert back to hours and minutes
  var hours = Math.floor(difference_ms / 3600000); // 1 Hour = 36000 Milliseconds
  var minutes = Math.floor((difference_ms % 3600000) / 60000); // 1 Minutes = 60000 Milliseconds

  // Return the result as "HH:MM"
  return hours + "h: " + (minutes < 10 ? "0" : "") + minutes + "m";
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp?.seconds * 1000);
  const options = {
    month: "long",
    day: "numeric",
  };
  const h = date.getHours();
  const m = date.getMinutes();
  const time = `${h}:${m < 10 ? "0" + m : m}`; // Format minutes with leading zero if needed
  const newDate = date.toLocaleDateString("en-US", options);

  return `${newDate} - ${time}`;
};

export const addDocument = async (collectionName, data) => {
  const docRef = await addDoc(collection(db, collectionName), data);
  return docRef;
};

export const updateDocument = async (collectionName, data) => {
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef);
  getDocs(q).then((querySnapshot) => {
    if (!querySnapshot.empty) {
      const docRef = doc(db, collectionName, querySnapshot.docs[0].id);
      updateDoc(docRef, { data: data });
    }
  });
};

export const getDocument = async (collectionName) => {
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef);
  const data = await getDocs(q).then(async (querySnapshot) => {
    // Check if the document exists
    if (!querySnapshot.empty) {
      // Document exists, update it
      const docRef = doc(db, collectionName, querySnapshot.docs[0].id);
      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      return docData;
    }
  });
  return data;
};

// Function to log or update call activity
export const logCallActivity = async (message, currentUserUid, receiverId) => {
  const logCollection = collection(db, "call_log"); // Collection reference

  // Create a query to find if the log between current user and receiver already exists
  const q = query(
    logCollection,
    where("sender", "==", currentUserUid),
    where("receiver", "==", receiverId)
  );

  try {
    // Get the documents that match the query
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Document already exists, update the first matching document
      const docRef = querySnapshot.docs[0].ref; // Get the reference to the existing document
      await updateDoc(docRef, {
        text: message,
        timestamp: serverTimestamp(), // Update timestamp
      });
    } else {
      // Document doesn't exist, add a new one
      await addDoc(logCollection, {
        sender: currentUserUid,
        receiver: receiverId,
        text: message,
        timestamp: serverTimestamp(), // New timestamp for a new entry
      });
    }

    console.log("Call log activity successfully logged or updated.");
  } catch (error) {
    console.error("Error logging call activity: ", error);
  }
};

// convert date time to another timezone
export const convertTZ = (date, tzString) => {
  // setLocalTime(new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })))
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};
