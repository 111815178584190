import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { EditPenIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

const DescriptionSection = ({ profileId }) => {
  const [profileAccount, setProfileAccount] = useState("");
  const [profileData, setProfileData] = useState("");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/settings/profile-setting");
  };
  // fetch profile data

  const getProfileAccount = async () => {
    const docRef = doc(db, "users", profileId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProfileAccount(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // fetch profile data
  const getProfileData = async () => {
    // Define the collection reference
    const collectionRef = collection(db, "profile");

    // Define the query to find the document with the correct user ID
    const q = query(collectionRef, where("profileId", "==", profileId));

    // Get the document reference
    await getDocs(q)
      .then(async (querySnapshot) => {
        // Check if the document exists
        if (!querySnapshot.empty) {
          // Document exists, update it
          const docRef = doc(db, "profile", querySnapshot.docs[0].id);
          const docSnap = await getDoc(docRef);
          setProfileData(docSnap.data());
        } else {
          // Document doesn't exist, add it
          // return addDoc(collectionRef, data);
        }
      })
      .then(() => {
        console.log("profile document get successfully!");
      })
      .catch((error) => {
        console.error("Error updating or adding user document: ", error);
      });
  };

  useEffect(() => {
    getProfileAccount();
    getProfileData();
  }, []);
  return (
    <div className="container flex flex-col w-full px-6 mx-auto sm:mb-32 mb-[50px] text-black bg-[#EFEFEF] sm:text-white sm:bg-[#121212]">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center w-full gap-2">
          <div className="flex flex-col w-full">
            <div className="flex flex-row mt-[57px] mb-[37px]">
              <div className="flex flex-row justify-between w-full">
                <div className="text-[24px]">
                  <span>{profileData.selfInfo}</span>
                  {/* <p>issue and I am looking to help you with your project until the filler words are com... <span className="text-[#959595]">Show more</span></p> */}
                </div>
              </div>
              <div className="flex flex-col flex-end w-[200px] sm:block">
                <button
                  className="bg-[#000000] flex self-end items-center justify-center flex-end w-[80%] rounded-full px-4 py-2 text-white mb-4"
                  onClick={handleClick}
                >
                  <span className="whitespace-nowrap  inline-block align-middle mr-2 text-[16px]">
                    Edit Profile
                  </span>
                  <EditPenIcon className="inline-block align-middle w-[14px]" />
                </button>
                {/* <button className="bg-[#343434] rounded-full px-4 py-2 text-white text-[16px]">+Create New Team</button> */}
              </div>
            </div>
            <div className="flex flex-row items-center address-localtime text-[20px]">
              <span className="mr-3">
                {profileAccount.location} - 12:28 pm local time
              </span>
              {/* <span className="mr-3">{profileAccount}</span> */}
              <div className="w-[15px] h-[15px] rounded-full border-width bg-[#2FEA7A] mr-[45px]"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-[29px]">
        <div className="flex items-center text-[20px] mb-2">
          <span className="font-bold">Skills</span>
          <span>&nbsp;(strongest to weakest)</span>
        </div>
        <div className="flex">
          <button
            className="bg-gray-400 px-2 py-1 text-black rounded-2xl"
            onClick={handleClick}
          >
            Add skills
          </button>
        </div>
        <div className="flex flex-row gap-2 mt-2">
          {profileData.Skills &&
            profileData.Skills.map((skill, id) => {
              return (
                <button
                  key={id}
                  className="px-4 py-1 rounded-[9px] bg-[#DEDEDE] text-black text-[15px]"
                >
                  {skill}
                </button>
              );
            })}
        </div>
      </div>
      <div className="flex flex-col mt-[29px]">
        {/* <div className="flex items-center mb-2">
                    <span className="font-bold mr-5 text-[20px]">Looking for talent</span>
                    <button className="flex flex-row items-center text-white text-[15px] bg-none  border-white border rounded-[7.5px]">
                        <DropdownComponent color="black" text="After Effect" option="" />
                    </button>
                </div> */}
        {/* <div className="flex flex-row gap-2 mt-2 text-[15px]">
                    <button className="px-4 py-1 rounded-[9px] bg-[#DEDEDE] text-black">After Effects</button>
                    <button className="px-4 py-1 rounded-[9px] bg-[#DEDEDE] text-black">Blender</button>
                    <button className="px-4 py-1 rounded-[9px] bg-[#DEDEDE] text-black">Foundry Nuke</button>
                </div> */}
      </div>
    </div>
  );
};

export default DescriptionSection;
