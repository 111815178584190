import { useNavigate } from "react-router-dom";
import { getDisplayName } from "~/utils/common";
import { db } from "~/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

function LastMessageWithUser({
  otherUserUid,
  contactData,
  otherUserName,
  otherUserColor,
}) {
  const currentUserUid = localStorage.getItem("myId"); // Replace with your actual user ID
  const navigate = useNavigate();

  const showMainChat = (sender, receiver) => {
    navigate(`/chat-inbox?sender=${sender}&receiver=${receiver}`);
    const msgRef = collection(db, "messages");

    // Define the query to find the document with the correct user ID
    const q = query(
      msgRef,
      where("sender", "==", receiver),
      where("seen", "==", false)
    );

    // Get the document reference
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((document) => {
        const message = document.data();
        if (message.seen == false) {
          // Set the seen field
          updateDoc(document.ref, {
            seen: true,
          });
        }
      });
    });
  };
  const messageIndex = contactData.text.indexOf("sent you an offer");
  let message = "";
  if (messageIndex !== -1) {
    message = contactData.text.slice(0, messageIndex);
  }
  // Get last message time
  const getLastMessageTime = (messageTimestamp) => {
    const currentDate = new Date();
    const lastMessageDate = new Date(messageTimestamp * 1000);
    var diffseconds =
      (currentDate.getTime() - lastMessageDate.getTime()) / 1000;
    if (diffseconds / 3600 < 24) {
      var rstime =
        (lastMessageDate.getHours() < 10 ? "0" : "") +
        lastMessageDate.getHours() +
        ":" +
        (lastMessageDate.getMinutes() < 10 ? "0" : "") +
        lastMessageDate.getMinutes();
      return rstime;
    } else if (diffseconds / 3600 > 24 && diffseconds / 3600 / 24 < 7) {
      var dwIdx = lastMessageDate.getDay();
      // Days of the week
      var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days[dwIdx];
    } else {
      return lastMessageDate.toLocaleDateString();
    }
  };

  return (
    <div
      className="hover:cursor-pointer flex flex-row items-center justify-between px-3 py-2 mb-3 text-black"
      onClick={() => showMainChat(currentUserUid, otherUserUid)}
    >
      {currentUserUid === contactData.sender ? (
        contactData.recvPhoto ? (
          <img
            src={contactData.recvPhoto}
            className="w-[52px] h-[52px] rounded-full object-cover"
            alt="logo"
          />
        ) : (
          <div
            className="w-[52px] h-[52px] rounded-full text-center mr-2 flex items-center justify-center"
            style={{ backgroundColor: otherUserColor }}
          >
            <p className="inline-block align-middle my-auto text-white text-[25px]">
              {getDisplayName(otherUserName)}
            </p>
          </div>
        )
      ) : contactData.photo ? (
        <img
          src={contactData.photo}
          className="w-[52px] h-[52px] rounded-full object-cover"
          alt="logo"
        />
      ) : (
        <div
          className="w-[52px] h-[52px] rounded-full text-center mr-2 flex items-center justify-center"
          style={{ backgroundColor: otherUserColor }}
        >
          <p className="inline-block align-middle my-auto text-white text-[25px]">
            {getDisplayName(otherUserName)}
          </p>
        </div>
      )}
      <div className="flex flex-col w-3/4">
        <div className="">
          {contactData.receiver && currentUserUid !== contactData.sender ? (
            <span className="text-[18px] font-bold mr-2">
              {contactData.name}
            </span>
          ) : (
            <span className="text-[18px] font-bold mr-2">{otherUserName}</span>
          )}
          <span className="text-[18px] text-gray-500">
            {getLastMessageTime(contactData?.timestamp?.seconds)}
          </span>
        </div>
        <span className="text-[16px]">
          {messageIndex == -1 ? contactData.text : message}
        </span>
      </div>

      {currentUserUid === contactData.sender && (
        <div className="w-[15px] h-[15px] rounded-full border-width border-[2px] border-[#AAAAAA]"></div>
      )}
      {currentUserUid === contactData.receiver && (
        <>
          {contactData.seen ? (
            <div
              className={`w-[15px] h-[15px] rounded-full border-width bg-[#4a4b50]`}
            ></div>
          ) : (
            <div
              className={`w-[15px] h-[15px] rounded-full border-width bg-[#2E6EF5]`}
            ></div>
          )}
        </>
      )}
    </div>
  );
}

export default LastMessageWithUser;
