import React, { useEffect, useState, useRef, useContext } from "react";
import { query, collection, where, onSnapshot } from "firebase/firestore";

import { db } from "~/firebase";
import OpenChatWindow from "~/components/organisms/Chat/OpenChatWindow";
import appContext from "~/contexts/AppContext";

const ChatContacts = ({}) => {
  // Mobile
  const { isMobile } = useContext(appContext);
  // Desktop
  const currentUserUid = localStorage.getItem("myId"); // Replace with your actual user ID
  // const conversations = {};
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState({});
  const conversationsRef = useRef({});

  const getContacts = () => {
    const messagesRef = collection(db, "messages");
    // Group messages by conversation
    const sentMessagesQuery = query(
      messagesRef,
      where("sender", "==", currentUserUid)
    );

    const unsubscribe1 = onSnapshot(sentMessagesQuery, (querySnapshot) => {
      const newConversations = { ...conversationsRef.current };
      querySnapshot.forEach((doc) => {
        const message = doc.data();
        const otherUserId = message.receiver;
        if (!newConversations[otherUserId]) {
          newConversations[otherUserId] = [];
        }
        newConversations[otherUserId].push(message);
      });
      // Sort each conversation by timestamp
      Object.keys(newConversations).forEach((otherUserId) => {
        newConversations[otherUserId].sort((a, b) => a.timestamp - b.timestamp);
      });
      conversationsRef.current = newConversations;
      setConversations(newConversations);
      setIsLoading(false);
    });

    const receivedMessagesQuery = query(
      messagesRef,
      where("receiver", "==", currentUserUid)
    );

    const unsubscribe2 = onSnapshot(receivedMessagesQuery, (querySnapshot) => {
      const newConversations = { ...conversationsRef.current };
      querySnapshot.forEach((doc) => {
        const message = doc.data();
        const otherUserId = message.sender;
        if (!newConversations[otherUserId]) {
          newConversations[otherUserId] = [];
        }
        newConversations[otherUserId].push(message);
      });
      // Fetch the last message for each conversation
      // Sort each conversation by timestamp
      Object.keys(newConversations).forEach((otherUserId) => {
        newConversations[otherUserId].sort((a, b) => a.timestamp - b.timestamp);
      });
      setConversations(newConversations);
      conversationsRef.current = newConversations;
      setIsLoading(false);
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  };

  useEffect(() => {
    const unsubscribe1 = getContacts();
    return () => {
      unsubscribe1();
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="w-full h-full bottom-0 right-3 rounded-t-[12px] flex flex-col float-right justify-end z-50">
          <div className="px-[25px] py-[20px] flex flex-row justify-between">
            <span className="text-[20px]">All Conversations</span>
          </div>
          <OpenChatWindow
            className="transition duration-300 mt-[150px]"
            conversations={conversations}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChatContacts;
