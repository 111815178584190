import { lazy, Suspense } from "react";

import { Routes, Route } from "react-router-dom";
import { Oval } from "react-loader-spinner";

import PrivateRoute from "./PrivateRoute";
import Page404 from "~/pages/404";
import Portfolio from "~/pages/Portfolio";
import SavedArtist from "~/pages/Portfolio/saved-artist";
import MainPortfolio from "~/pages/Portfolio/MainPortfolio";
import JobPosts from "~/pages/Experts/JobPosts";
import Post from "~/pages/Experts/JobPosts/Post";
import Leads from "~/pages/Experts/JobPosts/Leads";
import EachJobPost from "~/pages/Experts/JobPosts/EachJobPost";
import ChatContacts from "~/pages/ChatContacts";
import OrderHistory from "~/pages/Portfolio/OrderHistory";

// Lazy loaded components
const Layout = lazy(() => import("~/layout/Layout"));
const Login = lazy(() => import("~/pages/Login"));
const Register = lazy(() => import("~/pages/Register"));
const Settings = lazy(() => import("~/pages/Settings"));
const AccountSetting = lazy(() => import("~/pages/Settings/AccountSetting"));
const ProfileSetting = lazy(() => import("~/pages/Settings/ProfileSetting"));
const PortfolioSetting = lazy(() => import("~/pages/Settings/Portfolio"));
const Billing = lazy(() => import("~/pages/Settings/Billing"));
const ChatInbox = lazy(() => import("~/pages/Meets/ChatInbox"));
const CallChatInbox = lazy(() => import("~/pages/Meets/MeetExpertCall"));
const SearchTalent = lazy(() => import("~/pages/SearchTalent"));
const Home = lazy(() => import("~/pages/Home"));
const Admin = lazy(() => import("~/pages/Admin"));
const Terms = lazy(() => import("~/pages/Terms"));
const Notification = lazy(() => import("~/pages/Notification"));
const SupportRoom = lazy(() => import("~/pages/Meets/AskSupportCall"));

const Routings = () => {
  return (
    <Suspense
      fallback={
        <Oval
          height="80"
          className="absolute z-50 top-0 left-0 flex items-center justify-center h-full"
          width="80"
          color="#4fa94d"
          ariaLabel="oval-loading"
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
          wrapperStyle={{}}
          wrapperClass="flex bg-black opacity-70 justify-center h-full items-center absolute top-0 left-0 w-full z-50"
          visible={true}
        />
      }
    >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route index element={<Home />} />

          <Route
            element={
              <PrivateRoute
                allowedRoles={["admin", "sales", "expert", "customer"]}
              />
            }
          >
            <Route path="notification" element={<Notification />} />
            <Route path="settings" element={<Settings />}>
              <Route path="account-setting" element={<AccountSetting />} />
              <Route path="profile-setting" element={<ProfileSetting />} />
              <Route path="portfolio" element={<PortfolioSetting />} />
              <Route path="billing" element={<Billing />} />
            </Route>

            <Route path="portfolio/:id" element={<Portfolio />}>
              <Route path="order-history" element={<OrderHistory />} />
              <Route path="saved-artist" element={<SavedArtist />} />
              <Route index element={<MainPortfolio />} />
            </Route>

            <Route path="chat" element={<ChatContacts />} />
            <Route path="chat-inbox" element={<ChatInbox />} />
            <Route
              path="call-chat/:sender/:receiver"
              element={<CallChatInbox />}
            />
            <Route path="supportRoom/:creator" element={<SupportRoom />} />
            <Route path="order-instant" element={<SearchTalent />} />
          </Route>

          <Route
            element={
              <PrivateRoute allowedRoles={["admin", "sales", "expert"]} />
            }
          >
            <Route path="expert/job-post" element={<JobPosts />}>
              <Route path="leads" element={<Leads />} />
              <Route index element={<Post />} />
            </Route>
            <Route path="expert/job-post/:id" element={<EachJobPost />} />
          </Route>

          <Route element={<PrivateRoute allowedRoles={["admin", "sales"]} />}>
            <Route path="admin" element={<Admin />} />
          </Route>
          <Route path="terms" element={<Terms />} />
          <Route path="register" element={<Register />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default Routings;
