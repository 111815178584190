import React, { useEffect } from "react"

const datas = [
    {
        image: "/assets/img/DEFAULT_AVATAR_V004.png",
        name: "James Lee",
        net_status: "online",
        timezone: "Los Angeeles, USA - 12:26 pm local time",
        search_list: ["After Effects"],
        job: "Director"
    },
    {
        image: "/assets/img/default_avatar2.png",
        name: "T Carrey",
        net_status: "online",
        timezone: "Los Angeeles, USA - 12:26 pm local time",
        search_list: ["After Effects", "Cinema 4D", "Blender"],
        job: "Recruiter"
    }]
const Leads = () => {
    useEffect(() => {
        datas.map((data, idx) => {
            console.log("thanks", data);
        })
    }, [])
    return (
        <div className="flex flex-col h-auto gap-6 mx-auto sm:flex-row md:flex-row px-auto ">
            {
                datas.map((data, idx) => {
                    return (
                        <div className="flex flex-col py-[36px] px-[59px] bg-black max-w-[638px] h-auto rounded-[16px] border border-[1.6px] border-white" key={idx}>
                            <div className="flex flex-row gap-6 mb-6">
                                <img src={data.image} className="w-[113px] h-[113px] rounded-full object-cover" />
                                <div className="flex flex-col text-white">
                                    <div className="block mb-2">
                                        <span className="font-bold text-[32px]">{data.name}</span>
                                        <span className="w-[14px] h-[14px] bg-[#2FEA7A] rounded-xl"></span>
                                    </div>
                                    <span className="text-[19.2px]">{data.timezone}</span>
                                </div>
                            </div>
                            <div className="flex flex-row items-center mb-8">
                                <span className="mr-4 text-white">Searched for:</span>
                                {
                                    data.search_list.map(list => {
                                        return (
                                            <>
                                                <button className="px-[15px] h-[25px] rounded bg-[#DEDEDE] text-black text-[15px] mr-2">{list}</button>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className="flex flex-row justify-between text-white">
                                <span className="text-[28.8px]">{data.job}</span>
                                <button className="flex flex-row items-center justify-center bg-none py-[6px] px-[19px] rounded-3xl text-white border border-white">
                                    <svg className="w-5 h-5 mt-1 mr-2 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" /></svg>
                                    <span className="">introduce Yourself</span></button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Leads