import { Outlet } from "react-router-dom";

import JobpostNavbar from "~/components/molecules/Expert/JobpostNavbar";

const Jobpost = () => {
  return (
    <div className="flex flex-col h-[calc(100%-55px)] w-full py-[43px] mx-auto p-6 bg-[#121212] dark:bg-black overflow-scroll overflow-x-hidden">
      <JobpostNavbar />
      <Outlet />
    </div>
  );
};

export default Jobpost;
