import React, { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { getUserInfoByUid } from "~/utils/common";
import { serverTimestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "~/firebase";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appContext from "~/contexts/AppContext";
import ShakeHand from "~/assets/img/shakehand.png";

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PayPalButton = (props) => {
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: props.price, // amount to charge
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      if (details.status == "COMPLETED") {
        console.log("Payment completed successfully : ", details);
        props.payComplete();
      } else {
        console.log("There was an error : ", details);
      }
    });
  };

  return <PayPalButtons createOrder={createOrder} onApprove={onApprove} />;
};

const NoCredit = (props) => {
  const [myinfo, setMyinfo] = useState({});
  const [creditCount, setCreditCount] = useState("75");
  const [mycredit, setMycredit] = useState(0);
  const [price, setPrice] = useState(50.0);
  const [paypayModal, setPaypalModal] = useState(false);
  const { setUserData } = useContext(appContext);

  // Buying credit
  const buyCredit = async (recom) => {
    let creditPrice = 50.0;
    switch (creditCount) {
      case "75":
        creditPrice = 50.0;
        break;
      case "150":
        creditPrice = 90.0;
        break;
      case "500":
        creditPrice = 150.0;
        break;
      default:
        break;
    }
    if (recom == "recommend") {
      creditPrice = 39.0;
      setCreditCount("60");
    }
    setPrice(creditPrice);
    setPaypalModal(true);
    // props.closeModal(true);
  };

  const payComplete = async () => {
    let totalCredit = 0;
    totalCredit = Math.round((myinfo.credit + Number(creditCount)) * 100) / 100;

    // Update the credit counts with the given ID
    const docRef = doc(db, "users", props.senderId);
    if (creditCount == "60") {
      await updateDoc(docRef, {
        credit: totalCredit,
        monthly: true,
        subscribe_date: serverTimestamp(),
      });
    } else {
      await updateDoc(docRef, { credit: totalCredit });
    }
    await getinfo();
    const myData = JSON.parse(localStorage.getItem("userdata"));
    myData.credit = totalCredit;
    const newData = myData;
    localStorage.setItem("userdata", JSON.stringify(newData));
    setPaypalModal(false);
    props.closeModal(false);
  };

  // Get my info and save in state.
  const getinfo = async () => {
    const myInfo = await getUserInfoByUid(props.senderId);
    setUserData(myInfo);
    setMyinfo(myInfo);
    setMycredit(myInfo.credit);
  };

  // Credit option click
  const selectCreditOption = (ev) => {
    setCreditCount(ev.target.value);
  };

  useEffect(() => {
    console.log("MyUserId", props.senderId);
    getinfo();
  }, []);

  return (
    <div className="flex items-center">
      <div className="absolute top-[50%] translate-x-[-50%] translate-y-[-50%] z-50 left-[50%] trans min-w-[590px] flex flex-col p-[25px] h-auto bg-[#EFEFEF] rounded-[20px] shadow-[2px_4px_6px_rgba(0,0,0,0.5)]">
        <div className="card-header flex justify-end">
          <button
            className="p-2  text-black text-2xl"
            onClick={props.closeModal}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="flex justify-center items-center gap-2">
            <img src={ShakeHand} alt="no" className=" h-fit" />
            <div className="card-header text-center font-bold text-4xl whitespace-nowrap">
              Pay your workers with credits
            </div>
          </div>
          <div className="card-header text-center text-3xl">
            Your current account balance:
          </div>
          <div className="card-header text-center font-bold text-4xl">
            {myinfo.credit ? Math.round(myinfo.credit * 100) / 100 : 0} credits
          </div>
        </div>
        <div className="flex pl-20 mt-10 relative">
          <div className="font-normal text-[20px] leading-[24.2px]">
            <div>
              <input
                type="radio"
                name="creditCount"
                defaultChecked
                onClick={selectCreditOption}
                value="75"
              />{" "}
              75 credits/month <span className="font-bold">$39 /m</span>
            </div>
            <div className="pt-5">
              <input
                type="radio"
                name="creditCount"
                onClick={selectCreditOption}
                value="75"
              />{" "}
              75 credits <span className="font-bold">$50</span>
            </div>
            <div className="pt-5">
              <input
                type="radio"
                name="creditCount"
                onClick={selectCreditOption}
                value="150"
              />{" "}
              150 credits <span className="font-bold">$90</span>
            </div>
            <div className="pt-5">
              <input
                type="radio"
                name="creditCount"
                onClick={selectCreditOption}
                value="500"
              />{" "}
              500 credits <span className="font-bold">$150</span>
            </div>
          </div>

          {/* <div className="font-normal text-[20px] leading-[24.2px]">
            <div className="text-[#00BD4C] font-bold">20% DISCOUNT</div>
            <div className="pt-4 text-[20px]">75 Credits / month</div>
            <div className="text-[12px] leading-[15px]">(Cancel anytime)</div>
            <div className="pt-4"><span className="line-through">($50) </span><span className="text-[#00BD4C] font-bold">$39</span></div>
            <div className="pt-5">
              <button
                className="btn bg-black text-white w-full py-[14px] rounded-md"
                onClick={() => buyCredit("recommend")}
              >
                Monthly
              </button>
            </div>
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 top-0 h-full w-[1px] bg-[#878787] before:absolute before:top-1/2 before:content-['or'] before:font-primary before:text-xl before:leading-6 before:text-black before:bg-[#EFEFEF] before:left-0 before:-translate-x-1/2 before:-translate-y-1/2"></div> */}
        </div>
        <div className="pt-10 flex justify-center">
          <button
            className="btn w-fit bg-black text-white py-4 px-16 rounded-md"
            onClick={() => buyCredit()}
          >
            {/* 1-time */}
            Purchase
          </button>
        </div>
        {/* <div className="flex w-full">
          <div className="w-1/2"></div>
          <div className="w-1/2">
            <div className="text-center pt-2">(Recommended)</div>
          </div>
        </div> */}
        <div className="video-part pt-6">
          <h1 className="text-center">Lets make magic happen!</h1>
          <video
            autoPlay
            src="https://www.youtube.com/embed/XQrhkjDIl9Y?si=JQjyYuPxKWEKkGy3"
            className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110"
          />
        </div>
      </div>
      {paypayModal ? (
        <div className="absolute top-[50%] translate-x-[-50%] translate-y-[-50%] z-50 left-[50%] trans min-w-[590px] flex flex-col p-10 h-auto bg-[#EFEFEF] rounded-[20px] shadow-[2px_4px_6px_rgba(0,0,0,0.5)]">
          <div className="card-header flex justify-between items-center pb-2">
            <div className="card-header  font-bold text-[24px]">
              Total: ${price}
              <br />
              Choose Paypal or card
            </div>
            <button
              className="absolute top-5 right-5 px-4 py-2 rounded-md text-black"
              onClick={() => setPaypalModal(false)}
            >
              <AiOutlineClose />
            </button>
          </div>
          <PayPalScriptProvider
            options={{
              "client-id": paypalClientId,
            }}
          >
            <PayPalButton price={price} payComplete={payComplete} />
          </PayPalScriptProvider>
        </div>
      ) : null}
    </div>
  );
};

export default NoCredit;
