import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import Routings from "~/router/Routings";

function App() {
  const [g_callStatus, setCallStatus] = useState(
    JSON.parse(localStorage.getItem("g_callStatus")) || false
  );

  const siteId = process.env.REACT_APP_HOTJAR_ID || 5166078;
  const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION || 6;

  useEffect(() => {
    // Initialize Hotjar on component mount
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  useEffect(() => {
    localStorage.setItem("g_callStatus", JSON.stringify(g_callStatus));
  }, [g_callStatus]);

  return (
    <BrowserRouter>
      <Routings />
    </BrowserRouter>
  );
}

export default App;
