import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AdminLayoutProvider } from "./contexts/AdminContext";
import { AuthProvider } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense>
      <GoogleOAuthProvider clientId={process.env.RREACT_APP_GOOGLE_CLIENT_ID}>
        <ChakraProvider>
          <AdminLayoutProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </AdminLayoutProvider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
