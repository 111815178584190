import Button from "~/components/atoms/Buttons/Button";

const SendButton = ({ sendQuote, job, userData, index }) => {
  return (
    <Button
      onClick={() => {
        sendQuote(
          job.post_userid,
          job.jobId,
          userData.uid,
          userData.color,
          index
        );
      }}
      text="Send"
      styleClasses="text-[20px] text-[#363636] font-bold hover:bg-gray-300"
    />
  );
};

export default SendButton;
