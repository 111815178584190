import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "~/firebase";
import appContext from "~/contexts/AppContext";
import { getDisplayName, getUserInfoByUid } from "~/utils/common";
const SliderSection = ({ profileId }) => {
  const currentUserUid = localStorage.getItem("myId");
  const { userData } = useContext(appContext);
  const [profileAccount, setProfileAccount] = useState("");
  const [profileData, setProfileData] = useState("");
  const [portfolioData, setPortfolioData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  //Chat method
  const ChatWithOther = (otherId) => {
    navigate(`/chat-inbox?sender=${currentUserUid}&receiver=${otherId}`);
  };

  // fetch profile data
  const getProfileAccount = async () => {
    const profileData = await getUserInfoByUid(profileId);
    if (profileData) {
      setProfileAccount(profileData);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };
  // fetch profile data
  const getProfileData = async () => {
    // Define the collection reference
    const collectionRef = collection(db, "profile");
    // Define the query to find the document with the correct user ID
    const q = query(collectionRef, where("profileId", "==", profileId));
    // Get the document reference
    await getDocs(q)
      .then(async (querySnapshot) => {
        // Check if the document exists
        if (!querySnapshot.empty) {
          // Document exists, update it
          const docRef = doc(db, "profile", querySnapshot.docs[0].id);
          const docSnap = await getDoc(docRef);
          setProfileData(docSnap.data());
        } else {
          // Document doesn't exist, add it
          // return addDoc(collectionRef, data);
        }
      })
      .then(() => {
        console.log("profile document get successfully!");
      })
      .catch((error) => {
        console.error("Error updating or adding user document: ", error);
      });
  };

  const getPortfolio = async () => {
    // Define the collection reference
    const collectionRef = collection(db, "portfolios");
    // Define the query to find the document with the correct user ID
    const q = query(collectionRef, where("uid", "==", profileId));
    // Get the document reference
    await getDocs(q)
      .then(async (querySnapshot) => {
        // Check if the document exists
        if (!querySnapshot.empty) {
          // Document exists, update it
          const docRef = doc(db, "portfolios", querySnapshot.docs[0].id);
          const docSnap = await getDoc(docRef);
          console.log("portfolio-data", docSnap.data());
          setPortfolioData(docSnap.data());
        } else {
          // Document doesn't exist, add it
          // return addDoc(collectionRef, data);
        }
      })
      .then(() => {
        console.log("profile document get successfully!");
      })
      .catch((error) => {
        console.error("Error updating or adding user document: ", error);
      });
  };

  useEffect(() => {
    getProfileAccount();
    getProfileData();
    getPortfolio();
  }, []);

  return (
    <div className="relative flex flex-col justify-end sm:py-[35px] pb-[72px] h-[350px] sm:h-[700px] items-center w-full  object-fill bg-no-repeat bg-cover bg-center">
      <div className="absolute top-0 left-0 object-cover w-full h-full overflow-hidden bg-cover video-container z-0">
        <video
          playsInline
          muted
          autoPlay={isPlaying}
          src="/def_video.mp4"
          loop
          ref={videoRef}
          className="absolute object-cover min-w-full min-h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          style={{ WebkitMediaControls: "display(none)" }}
        ></video>
      </div>
      <div className="bg-blend-overlay absolute left-0 bottom-0 w-full h-full bg-gradient-to-b bg-gradient-[180deg] from-black/0 to-black opacity-90 px-8 py-4  right-2 transition-all duration-500"></div>
      <div className="bg-blend-overlay z-10 flex flex-col w-full gap-2">
        <div className="flex flex-col items-center">
          {profileAccount.image ? (
            <img
              src={profileAccount?.image}
              className="h-[120px] w-[120px] rounded-full object-cover"
              alt="logo"
            />
          ) : (
            <>
              <div
                className="h-[120px] w-[120px] rounded-full text-center mr-2 flex items-center justify-center"
                style={{ backgroundColor: userData?.color }}
              >
                <p className="inline-block align-middle my-auto text-white text-[25px]">
                  {userData ? getDisplayName(userData.username) : null}
                </p>
              </div>
            </>
          )}
          <span className="font-bold text-[30px] text-white mb-3">
            {profileAccount.username}
          </span>
          <span className="text-[20px] text-white mb-3">
            {profileData.jobTitle}
          </span>
        </div>
        <div className="flex flex-row items-center space-x-[21px] justify-center">
          <button
            className="flex items-center gap-2 rounded-md h-[38px] py-[12px] px-[30px] border-solid border border-white bg-white text-[20px]"
            onClick={() => ChatWithOther(profileId)}
          >
            <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
            </svg>
            <span className="text-[18px] leading-[22px] font-medium">Chat</span>
          </button>
          <button
            className="flex items-center gap-2 rounded-md h-[38px] py-[12px] px-[30px] border-solid border border-white bg-white text-[20px]"
            onClick={() => ChatWithOther(profileId)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-phone"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
            <span className="text-[18px] leading-[22px] font-medium">Call</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderSection;
