import { MdDeleteForever } from "react-icons/md";
import Button from "~/components/atoms/Buttons/Button";

const DeletePostButton = ({ userRole, deleteJob, job }) => {
  return (
    userRole === "sales" && (
      <Button
        onClick={() => deleteJob(job)}
        text="Delete"
        icon={MdDeleteForever}
        styleClasses="bg-red-700 rounded-full text-white font-primary hover:bg-red-500 border-red-700"
      />
    )
  );
};

export default DeletePostButton;
