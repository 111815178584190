import React from "react";
import { MultiplicationIcon } from "../../../assets/icons";
const opts = {
    playerVars: {
        modestbranding: 1, // add this to remove the YouTube play button
    },
};
export default function Modal({ setOpenModal, object, description, title }) {
    return (
        <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                    className="fixed inset-0 w-full h-full bg-black opacity-70"
                >
                    <div
                        className="fixed flex top-[40px] right-[40px]"
                        onClick={() => setOpenModal(false)}
                    ><MultiplicationIcon className="w-[30px] h-[30px]" /></div>
                </div>
                <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-[1255px] mx-auto h-auto pb-8 bg-none rounded-md shadow-lg">
                        <div className="w-full mt-0 sm:flex">
                            <div className="flex flex-col w-full">
                                {/* <img src={object} className="w-full h-[706px] object-cover" /> */}
                                <iframe autoPlay={true} src={object} className="w-full h-[706px] object-cover" opts={opts} muted></iframe>

                                <span className="text-white text-[37.5px]">{title}</span>
                                <span className="text-white text-[19px]">{description}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    