import { IoMdCreate } from "react-icons/io";
import Button from "~/components/atoms/Buttons/Button";

const EditPostButton = ({
  currentUserUid,
  job,
  setCurrentSelectedJob,
  setEditPost,
}) => {
  return (
    currentUserUid === job.post_userid && (
      <Button
        onClick={() => {
          setCurrentSelectedJob(job);
          setEditPost(true);
        }}
        text="Edit"
        icon={IoMdCreate}
        styleClasses="bg-gray-400 rounded-full border-gray-500 font-primary hover:bg-gray-300 hover:text-black"
      />
    )
  );
};

export default EditPostButton;
