import PropTypes from "prop-types";

// Button component
const Button = ({ onClick, text, icon: Icon = null, styleClasses = "" }) => {
  return (
    <button
      className={`flex items-center gap-2 h-[20px] py-[18px] px-[25px] text-[15px] font-bold ${styleClasses}`}
      onClick={onClick}
    >
      {Icon && <Icon />} {text}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  styleClasses: PropTypes.string,
};

export default Button;
