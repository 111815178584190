import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "~/contexts/AuthContext";

const PrivateRoute = ({ allowedRoles = [] }) => {
  const userRole = localStorage.getItem("userRole");
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
