import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const tabsData = [
  {
    label: "+ New Job Post",
    path: "/",
  },
];
const JobpostNavbar = () => {
  // Decide where the nav item is active
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(2);

  const tabsRef = useRef([]);

  useEffect(() => {
    tabsData.map((data, idx) => {
      if (data.path === location.pathname) {
        setActiveTabIndex(idx);
      }
    });
  }, []);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <div className="flex px-auto w-full bg-[#121212]  mb-4">
      <div className="relative nav-items mx-auto text-center h-[45px]">
        <div className="flex space-x-8 relative">
          {tabsData.map((tab, idx) => {
            return (
              <Link
                to={tab.path}
                key={idx}
                ref={(el) => (tabsRef.current[idx] = el)}
                className="text-white leading-4"
                onClick={() => setActiveTabIndex(idx)}
              >
                <button className="px-[18px] py-[12px] font-primary text-xl text-black-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                  {tab.label}
                </button>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default JobpostNavbar;
