import { Outlet, useParams } from "react-router-dom";

import profileContext from "~/contexts/ProfileContext";

import Topbar from "~/components/molecules/Topbar/Topbar";

function Landing() {
  const currentUserUid = localStorage.getItem("myId");
  const { id } = useParams();
  let profileId = id == undefined ? currentUserUid : id.split("=")[1];
  return (
    <profileContext.Provider value={{ profileId }}>
      <div className="flex h-full w-full px-auto bg-[#121212] dark:bg-black overflow-scroll">
        <div className="flex flex-col flex-1 w-full pt-[25px] mx-auto min-h-screen py-6 sm:bg-[#121212] bg-[#121212] dark:bg-black">
          {profileId == currentUserUid || profileId == "" ? <Topbar /> : null}
          <Outlet />
        </div>
      </div>
    </profileContext.Provider>
  );
}

export default Landing;
