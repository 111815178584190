import React, { useState, useContext, useRef } from "react";

import profileContext from "../../contexts/ProfileContext";

import SliderSection from "../../components/organisms/Portfolio/SliderSection";
import DescriptionSection from "../../components/organisms/Portfolio/DescriptionSection";
import Modal from "../../components/organisms/Portfolio/Modal";

const datas = [
  {
    videourl: "https://www.youtube.com/embed/XQrhkjDIl9Y?si=JQjyYuPxKWEKkGy3",
    description: "",
  },
  {
    videourl: "https://player.vimeo.com/video/497547815?h=cc28e47219",
    description:
      "Hello I will help you with your tracking problems and I will give you th the big solution, this is my issue...",
    title: "Demoreel",
  },
  {
    videourl: "https://player.vimeo.com/video/447732762?h=527e6beaa0",
    description: "",
  },
];

const MainPortfolio = () => {
  const { profileId } = useContext(profileContext);
  const [activeIndex, setActiveIndex] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  return (
    <div className="flex flex-col min-h-screen bg-[#121212] dark:bg-black">
      <SliderSection profileId={profileId} />
      <DescriptionSection profileId={profileId} />
      {/* <div className="grid grid-cols-3 w-full lg:grid-cols-3 pb-[60px] px-[0px]">
                <div className="relative block overflow-hidden transition-all duration-500 bg-black cursor-pointer group" onClick={() => {
                    setShowModal
                        (true); setActiveIndex(0)
                }}>
                    <img src="/assets/img/portfolio/demoree.jpg" className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" />

                    <video autoPlay={isPlaying} src="https://www.youtube.com/embed/XQrhkjDIl9Y?si=JQjyYuPxKWEKkGy3" ref={videoRef} className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" muted></video>

                    <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-b bg-gradient-[180deg] from-black/0 to-black opacity-0 hover:opacity-90 px-8 py-4  right-2 transition-all duration-500">
                        <div className="relative w-full h-full">
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden bg-black bg-no-repeat bg-cover cursor-pointer group" onClick={() => {
                    setShowModal
                        (true); setActiveIndex(1)
                }}>
                    <img src="/assets/img/portfolio/compositing.png" className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" />

                    <video autoPlay={isPlaying} src="https://player.vimeo.com/video/497547815?h=cc28e47219" ref={videoRef} className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" muted></video>


                    <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-b bg-gradient-[180deg] from-black/0 to-black opacity-0 hover:opacity-90 px-8 py-4  right-2 transition-all duration-500">
                        <div className="relative w-full h-full">
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden bg-black bg-no-repeat bg-cover cursor-pointer group" onClick={() => {
                    setShowModal
                        (true); setActiveIndex(2)
                }}>
                    <img src="/assets/img/portfolio/3d.jpg" className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" />

                    <video autoPlay={isPlaying} src="https://player.vimeo.com/video/447732762?h=527e6beaa0" ref={videoRef} className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" muted></video>

                    <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-b bg-gradient-[180deg] from-black/0 to-black opacity-0 hover:opacity-90 px-8 py-4  right-2 transition-all duration-500">
                        <div className="relative w-full h-full">
                        </div>
                    </div>
                </div>
            </div> */}
      {/* {showModal && <Modal setOpenModal={setShowModal} object={datas[activeIndex].videourl} title={datas[activeIndex].title} description={datas[activeIndex].description} />} */}
      {showModal && (
        <Modal
          setOpenModal={setShowModal}
          object={datas[activeIndex].videourl} // Replace with the video file path
          title={datas[activeIndex].title}
          description={datas[activeIndex].description}
        />
      )}

      {/* <div className="flex flex-col w-full">
                <span className="text-white text-[30px] text-center mb-[20px]">Resources</span>
                <div className="grid grid-cols-2 w-full lg:grid-cols-2 mb-[73px]">
                    <div className="relative overflow-hidden bg-black bg-no-repeat bg-cover group">
                        <img src="/assets/img/portfolio/nuke-piss.png" className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" />
                        <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-b bg-gradient-[180deg] from-black/0 to-black opacity-0 hover:opacity-90 px-8 py-4  right-2 transition-all duration-500">
                            <div className="relative w-full h-full">
                                <div className="absolute bottom-0 text-[36px] text-white mb-auto mt-auto">Matte Painting</div>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-hidden bg-black bg-no-repeat bg-cover group">
                        <img src="/assets/img/portfolio/10k-project.png" className="object-cover w-full transition duration-300 ease-in-out group-hover:scale-110" />
                        <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-b bg-gradient-[180deg] from-black/0 to-black opacity-0 hover:opacity-90 px-8 py-4  right-2 transition-all duration-500">
                            <div className="relative w-full h-full">
                                <div className="absolute bottom-0 text-[36px] text-white mb-auto mt-auto">Matte Painting</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className="w-full bg-[#D9D9D9] sm:px-[239px] px-[13px] pt-[31px] pb-[141px]">
                <BottomSliderSection />
            </div> */}
    </div>
  );
};

export default MainPortfolio;
