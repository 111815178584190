import Button from "~/components/atoms/Buttons/Button";

const AttachmentButton = ({
  job,
  setCurrentSelectedJob,
  setEditPost,
  currentUserUid,
}) => {
  return (
    <Button
      onClick={() => {
        setCurrentSelectedJob(job);
        setEditPost(currentUserUid === job.post_userid);
      }}
      text="Add description, attach files, etc"
      styleClasses="my-5 text-[18px] font-primary font-bold text-[#3d3eab] border-b-2 border-blue-900 hover:text-blue-700 hover:border-blue-700"
    />
  );
};

export default AttachmentButton;
