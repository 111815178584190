import React, { useEffect, useState, useContext, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import LastMessageWithUser from "./LastMessageWithUser";
import appContext from "~/contexts/AppContext";
import { getContactName, getContactColor } from "~/firebase/contact";
import { db } from "~/firebase";

const OpenChatWindow = ({ conversations }) => {
  const { isMobile } = useContext(appContext);
  const [search] = useSearchParams();
  const location = useLocation();
  const [otherUserNames, setOtherUserNames] = useState({});
  const [otherUserColors, setOtheruserColors] = useState({});
  const receiver = search.get("receiver");

  // Mark messages as seen when the component mounts
  useEffect(() => {
    if (receiver) {
      const msgRef = collection(db, "messages");
      const q = query(
        msgRef,
        where("sender", "==", receiver),
        where("seen", "==", false)
      );

      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((document) => {
          const message = document.data();
          if (!message.seen) {
            updateDoc(document.ref, {
              seen: true,
            });
          }
        });
      });
    }
  }, [location, conversations]);

  // Fetch other user names and colors
  useEffect(() => {
    const getUserContacts = async () => {
      let userNames = {};
      let userColors = {};

      await Promise.all(
        Object.keys(conversations).map(async (otherUserId) => {
          const [contactUserName, contactUserColor] = await Promise.all([
            getContactName(otherUserId),
            getContactColor(otherUserId),
          ]);
          userNames[otherUserId] = contactUserName;
          userColors[otherUserId] = contactUserColor;
        })
      );

      setOtherUserNames(userNames);
      setOtheruserColors(userColors);
    };

    if (conversations && Object.keys(conversations).length > 0) {
      getUserContacts();
    } else {
    }
  }, [conversations]);

  // Memoize sorted conversations
  const sortedConversations = useMemo(() => {
    if (!conversations) return [];

    let result = [];
    for (let userId of Object.keys(conversations)) {
      result.push({
        id: userId,
        data: conversations[userId],
      });
    }

    result.sort((c1, c2) => {
      const lastMsg1 = c1.data[c1.data.length - 1];
      const lastMsg2 = c2.data[c2.data.length - 1];
      return lastMsg2?.timestamp?.seconds - lastMsg1?.timestamp?.seconds;
    });

    return result;
  }, [conversations]);

  if (!sortedConversations || sortedConversations.length === 0) {
    return null; // Handle empty state
  }

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col w-full h-full overflow-x-hidden overflow-y-scroll bg-[#EFEFEF]">
          {sortedConversations.map((conversation, index) => (
            <LastMessageWithUser
              key={index}
              contactData={conversation.data[conversation.data.length - 1]}
              otherUserUid={conversation.id}
              otherUserName={otherUserNames[conversation.id]}
              otherUserColor={otherUserColors[conversation.id]}
            />
          ))}
        </div>
      ) : (
        <div className="w-full flex flex-col h-[495px] bg-white overflow-y-scroll overflow-x-hidden cursor-pointer">
          {sortedConversations.map((conversation, index) => (
            <LastMessageWithUser
              key={index}
              contactData={conversation.data[conversation.data.length - 1]}
              otherUserUid={conversation.id}
              otherUserName={otherUserNames[conversation.id]}
              otherUserColor={otherUserColors[conversation.id]}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default OpenChatWindow;
