import { getDoc, doc } from "firebase/firestore";
import { db } from "~/firebase";
import { getUserInfoByUid } from "~/utils/common";

export const getContactName = async (docId) => {
  // deleteDocumentsWithSenderId()
  try {
    const contactAccount = await getUserInfoByUid(docId);
    if (contactAccount == undefined) return;
    return contactAccount.username;
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
};

export const getContactColor = async (docId) => {
  // deleteDocumentsWithSenderId()
  try {
    const contactAccount = await getUserInfoByUid(docId);
    if (contactAccount == undefined) return;
    return contactAccount.color;
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
};